<template>
	<div id="body">
		<div id="wx-banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/WXBANNER.jpg" >
		</div>
		<div id="wx_tit">
			<div id="wx_tit_header">
				<p>五大专属功能，手机预约一步到位</p>
				<span>融恒店能够自动同步你的门店商品信息到你的手机店铺，同时打通门店会员与微信粉丝数据，五大在线服务功能，能帮你真正实现数字化的智能门店</span>
			</div>
			<div id="wx-img">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wximg.jpg" >
			</div>
			<div id="wx_tit_main">
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='weixin1'></icon-svg>
					<p>微信店铺</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fukuan'></icon-svg>
					<p>会员中心</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='gongdanpaigongguanli'></icon-svg>
					<p>在线预约</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
					<p>在线商城</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fukuan1'></icon-svg>
					<p>移动支付</p>
				</div>
			</div>
			<div id="WX-shop" style="height: 600PX;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wximg002.png" >
				<div id="WX-shop_NAME">
					<p id="sub">微信店铺</p>
					<p id="Ti">微信店铺,线下门店，一键搬到手机</p>
					<span>线下门店，一键搬到手机  无需设置，你的门店商品，会员，库存信息就能实时同步到微店，让你的客户可以直接通过微店在线预约，在线购买，体验就像在使用专业的购物APP</span>
				</div>
			</div>
			<div id="WX-shop">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MBcenter.png" >
				<div id="WX-shop_NAME">
					<p id="sub">微信会员中心</p>
					<p id="Ti">源源不断，回流现金</p>
					<span>融恒店可以打通门店会员与微信粉丝数据。会员无需到店、无需实体会员卡，即可通过微信查询积分余额，甚至在线充值购物卡。此外，融融恒支持充值赠送等营销功能，后台营销信息通过微信一键推送，助您实现精准营销、快速回流资金</span>
				</div>
			</div>
			<div id="WX-shop" style="height: 850PX;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/WEBSHOP.png" >
				<div id="WX-shop_NAME">
					<p id="sub">在线商城</p>
					<p id="Ti">无需分成给其他平台</p>
					<span>试想入驻其他平台，不仅需要利润分成，顾客的偏好亦无法直接获得。融恒店支持自建商城平台，通过微信下单，轻松拓展订单量，还有预约订位功能，提前预约支付，减少客户到店等待时间。</span>
				</div>
			</div>
			<div id="paybgc">
				<i class="el-icon-circle-check"></i>
				<p>免费帮你开通微信、支付宝支付</p>
				<span>对接支付宝、微信支付不知如何下手？</br>
							现在您拥有专人帮您免费开通手机支付，即刻拥有专用的收款二维码，安
							全高效又快速，平均3秒内就能完成一次收付款。</span>
			</div>
		</div>
		<div id="wshopbox">
			<p id="on_wx">限时免费体验融恒店</p>
			<p id="tw_wx">你手机里的门店，在线预约一步到位</p>
			<p id="tw_wx">涨粉神器 | 在线预约 | 在线商城 | 微信会员营销</p>
			<p id="tw_wx">￥0</p>
			<button @click="tyfun" type="button">免费体验</button>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		methods:{
			tyfun(){
				window.location.href = "http://member.ronghengzn.com/#/";
				
			}
			
		}
		}
</script>

<style scoped="scoped" lang="less">
	#wx-banner{
		width: 100%;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	#wx_tit{
		width: 100%;
		
		// background-color: #FF0000;
		text-align: center;
		padding-top: 5%;
		#wx_tit_header{
			width: 100%;
			height: 140px;
			p{
				font-size: 22px;
			}
			span{
				display: block;
				width: 90%;
				margin-left: 5%;
				font-size: 14px;
				color: #6f6f6f;
			}
		}
		#wx_tit_main{
			width: 100%;
			height: 200px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			#wx_tit_main_box{
				width: 32%;
				height: 49%;
				margin-left: 1%;
				.iconclas{
					font-size: 40px;
				}
				p{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
		#WX-shop{
			width: 100%;
			height: 700px;
			img{
				width: 100%;
				max-height: 80%;
			}
			#WX-shop_NAME{
				#sub{
					font-size: 20px;
					margin-top: 3%;
				}
				#Ti{
					font-size: 24px;
				}
				span{
					display: block;
					font-size: 14px;
					width: 80%;
					margin-left: 10%;
				}
			}
		}
		#paybgc{
			width: 100%;
			height: 300px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonepay.jpg');
			background-size: 100% 100%;
			color: #FFFFFF;
			text-align: center;
			padding-top: 20%;
			i{
				font-size: 50px;
				margin-bottom: 1rem;
			}
			p{
				font-size: 24px;
			}
			span{
				display: block;
				width: 80%;
				margin-left: 10%;
				font-size: 14px;
			}
		}
	}
	#wshopbox{
		width: 100%;
		height: 300px;
		background-color: #eaeaea;
		text-align: center;
		padding-top: 15%;
		#on_wx{
			font-size: 28px;
			color: #fd3f31;
		}
		#tw_wx{
			font-size: 17px;
		}
		button{
			width: 40%;
			height: 50px;
			background-color: #fd3f31;
			border-radius: 5px;
			color: #FFFFFF;
		}
	}
</style>
